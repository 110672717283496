
  
.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 20px;
  }
  
  /* Form container styling */
  .contact-container {
    width: 100%;
    max-width: 400px; /* Reduced the max-width to make the form smaller */
    margin-bottom: 20px;
    padding: 15px; /* Reduced padding */
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }
  
  .contact-container h1 {
    font-family: 'Montserrat Light Alt1', sans-serif;
    text-align: center;
    margin-bottom: 15px; /* Reduced margin-bottom */
    font-weight: bold;
    color: #0b0031;
  }
  
  .contact-container p {
    text-align: center;
    margin-bottom: 15px; /* Reduced margin-bottom */
    font-size: 1.1rem;
    color: #555555;
  }
  
  .contact-container .form-label {
    font-weight: bold;
    color: #333333;
    text-align: left;
  }
  
  .contact-container .form-control {
    border-radius: 5px;
    padding: 8px; /* Reduced padding */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .contact-container .form-control:focus {
    border-color: #0b0031;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  }
  
  .contact-container .submit-button {
    width: 100%;
    padding: 8px; /* Reduced padding */
    border-radius: 5px;
    font-weight: bold;
    background-color: #0b0031;
    color: #ffffff;
    border: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .contact-container .submit-button:hover {
    background-color: #83b300;
    transform: translateY(-2px);
  }
  
  /* Logo container styling */
  .contact-logo {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* padding: 20px; */
    margin-left: 2rem;
  }
  
  .contact-logo img {
    max-width: 50%;
    height: auto;
    /* padding: 20px; */
  }
  
  .contact-h1{
    font-family: 'Montserrat Light Alt1', sans-serif;
    font-weight: 600;
  }
  /* Responsive design for larger screens */
  @media (min-width: 768px) {
    .contact-page {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  
    .contact-container, .contact-logo {
      max-width: 45%; /* Reduced the max-width to make the form smaller */
    }
  
    .contact-container {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    .contact-logo {
      display: flex;
      justify-content: flex-start;
      padding-left: 20px;
    }
  }
  