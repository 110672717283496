.modal-enquiry {
    position: relative;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px; /* Adjusted size for a more compact modal */
    width: 90%;
    max-height: 80%; /* Adjusted height for better fit */
    overflow-y: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.modal-overlay-enquiry {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.modal-close-button-enquiry {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 24px;
    cursor: pointer;
}

.CCTV-Enquiry-form{
    font-family: 'Montserrat Light Alt1', sans-serif;
}
.form-group {
    margin-bottom: 1px;
}

.form-group label {
    display: block;
    font-weight: bold;
}

.form-group select,
.form-group input,
.form-group textarea {
    width: 100%;
    padding: 2px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.checkbox-group,
.radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.camera-group {
    display: flex;
    align-items: center;
    /* gap: 10px; */
    /* margin-bottom: 10px; */
}

.add-camera-btn {
    background-color: #0b0031;
    color: #fff;
    border: none;
    padding: 1px 5px;
    cursor: pointer;
    border-radius: 4px;
}

.add-camera-btn:hover {
    background-color: #0056b3;
}

.submit-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
}

.submit-btn:hover {
    background-color: #0056b3;
}

/* Layout adjustments for form fields */

/* Recorder Type and Channel Selector in same row */
.form-group-recorder-channel {
    display: flex;
    gap: 10px;
    /* margin-bottom: 15px; */
}

.form-group-recorder-channel > .form-group {
    flex: 1;
}

.form-group-recorder-channel select {
    width: 100%;
}

/* Camera Type and Quantity in same row, Add Another Camera button below */
.camera-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.camera-group .form-group {
    flex: 1;
}

/* Name and Phone in same row */
.form-group-name-phone {
    display: flex;
    gap: 10px;
    /* margin-bottom: 15px; */
}

.form-group-name-phone > .form-group {
    flex: 1;
}

/* Adjustments for specific form groups */
.form-group textarea {
    width: 100%;
}

.remove-camera-btn {
    background: transparent;
    border: none;
    color: #ff0000; /* Red color to indicate removal */
    font-size: 18px;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.remove-camera-btn:hover {
    color: #cc0000; /* Darker red on hover */
}