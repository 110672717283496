

.navbar {
  border-bottom: 1px solid #e3f2fd;
  background-color: #0b0031;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-link {
  font-size: 1.2rem;
  color: #0a0202;
}

/* .navbar-nav {
  list-style: none;
  padding-left: 0;
} */


.navbar-heading{
  font-family: 'Montserrat Light Alt1', sans-serif;
  font-weight: 900;
  padding-top: 1rem;
  padding-right: 2rem;
  color: #ffc99c;
}

