.carousel-container {
    padding: 10px;
    border-radius: 5px;
    /* margin-top: 70px;  */
    /* height: 100px; */
  }
  
  .carousel-wrapper {
    height: auto; 
  
  }
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  