/* Enquiry Modal Background */
.enquiry-modal {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Modal Content */
  .enquiry-modal-content {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    background-color: #fefefe;
    border: 1px solid #888;
    width: 90%;
    max-width: 500px;
    border-radius: 8px;
    box-sizing: border-box;
    overflow-y: auto; /* Ensure the content can scroll if needed */
    max-height: 90vh; /* Limit the height to fit within the viewport */
    margin-top: 50px; /* Add some top margin to avoid overlap */
  }
  
  .enquiry-heading{
    font-family: 'Montserrat Light Alt1', sans-serif;
  }
  
  
  /* Close Button */
  .enquiry-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .enquiry-close:hover,
  .enquiry-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Form Elements */
  label {
    margin-top: 10px;
    display: block;
    font-weight: bold;
  }
  
  .input-row {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 10px 0;
  }
  
  .input-row > * {
    flex: 1;
    margin-right: 10px;
  }
  
  .input-row > *:last-child {
    margin-right: 0;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    resize: vertical; /* Allow vertical resizing only */
  }
  
  .enquiry-card-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 0;
  }
  
  .enquiry-card {
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    flex: 1 1 calc(20% - 10px); /* Flexbox for responsive layout */
    margin: 5px;
  }
  
  .enquiry-card img {
    margin-top: 10px;
    width: 30px;
    height: 30px;
  }
  
  .enquiry-card p {
    margin-top: 1rem;
    font-size: small;
  }
  
  .enquiry-card:hover,
  .enquiry-card.enquiry-selected {
    border-color: #007bff;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  }
  
  /* Buttons */
  .buttons-row {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .enquiry-submit-btn,
  .enquiry-cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    flex: 1;
    margin: 0 5px; /* Space between buttons */
  }
  
  .enquiry-submit-btn {
    background-color: #0b0031;
    color: white;
  }
  
  .enquiry-cancel-btn {
    background-color: #ccc;
    color: black;
  }
  
  .enquiry-submit-btn:hover,
  .enquiry-submit-btn:focus {
    color: #0b0031;
    background-color: mediumseagreen;
    text-decoration: none;
    cursor: pointer;
    /* border-style: solid ; */
    /* border-color: #0b0031; */
  }